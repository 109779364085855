import ReactGA from "react-ga4";
// import BankDetails from "../Pages/VendorManagement/OnboardingForms/BankDetails";
// import CompanyDetails from "../Pages/VendorManagement/OnboardingForms/CompanyDetails";
// import Documents from "../Pages/VendorManagement/OnboardingForms/Documents";
// import PanGstDetails from "../Pages/VendorManagement/OnboardingForms/PanGstDetails";
// import ServiceDetails from "../Pages/VendorManagement/OnboardingForms/ServiceDetails";
import { imageBaseURL } from "../constants/utils";

//Initialize React Google Analytics
export const analyticsInitialize = () => {
  if (process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA?.initialize(process.env.REACT_APP_GA_TRACKING_ID);
};
//Google Analytics Helper Function for Page View
export const analyticsPageview = (hitType, page, title) => {
  if (process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA?.send({ hitType, page, title });
};
//Google Analytics Helper Function for triggering events
export const analyticsEvent = (category, action, label) => {
  if (process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA?.event({ category, action, label });
};

export const pageLimit = 5;

export const InvestigationButtonLabels = ["Flag", "Approve", "Reject"];

export const getCaseManagementTablistItems = (data = {}) => {
  let tabData = [
    {
      label: `${data?.open || 0} Open`,
    },
    {
      label: `${data?.hold || 0} Hold`,
    },
    {
      label: `${data?.allocated || 0} Allocated`,
    },
    {
      label: `${data?.qc || 0} QC`,
    },
    // {
    //   label: `${data?.query || 0} Query`,
    // },
    {
      label: `${data?.recommended || 0} Recommended`,
    },
    {
      label: `${data?.submitted || 0} Submitted`,
    },
    {
      label: `${data?.rejected || 0} Rejected`,
    },
    {
      label: `${data?.closed || 0} Closed`,
    },
  ];
  return tabData;
};
export const getCaseManagementBSTablistItems = (data = {}) => {
  let tabData = [
    {
      label: `${data?.open || 0} Open`,
    },
    // {
    //   label: `${data?.allocated || 0} Allocated`,
    // },
    // {
    //   label: `${data?.qc || 0} QC`,
    // },
    // {
    //   label: `${data?.query || 0} Query`,
    // },
    {
      label: `${data?.recommended || 0} Recommended`,
    },
    {
      label: `${data?.submitted || 0} Submitted`,
    },
    // {
    //   label: `${data?.rejected || 0} Rejected`,
    // },
    {
      label: `${data?.closed || 0} Closed`,
    },
  ];
  return tabData;
};

export const stateShortFormObj = {
  MAHARASHTRA: "MH",
  GUJARAT: "GJ",
  GOA: "GA",
  KARNATAKA: "KA",
  KERALA: "KL",
  PUNJAB: "PB",
  "ANDHRA PRADESH": "AP",
  "ARUNACHAL PRADESH": "AL",
  ASSAM: "AS",
  BIHAR: "BR",
  CHHATTISGARH: "CT",
  CHATTISGARH: "CT",
  GUJARAT: "GT",
  HARYANA: "HR",
  "HIMACHAL PRADESH": "HP",
  JHARKHAND: "JH",
  "MADHYA PRADESH": "MP",
  MANIPUR: "MN",
  MEGHALAYA: "ML",
  MIZORAM: "MZ",
  NAGALAND: "NL",
  ORISSA: "OR",
  RAJASTHAN: "RJ",
  SIKKIM: "SK",
  "TAMIL NADU": "TN",
  TELANGANA: "TG",
  TRIPURA: "TR",
  UTTARAKHAND: "UL",
  UTTARANCHAL: "UL",
  "UTTAR PRADESH": "UP",
  "WEST BENGAL": "WB",
  "ANDAMAN AND NICOBAR ISLANDS": "AN",
  "ANDAMAN & NICOBAR ISLANDS": "AN",
  CHANDIGARH: "CH",
  "DADRA AND NAGAR HAVELI": "DN",
  "DADRA & NAGAR HAVELI": "DN",
  "DAMAN AND DIU": "DD",
  "DAMAN & DIU": "DD",
  DELHI: "DL",
  "JAMMU AND KASHMIR": "JK",
  LADAKH: "LA",
  LAKSHADWEEP: "LD",
  PONDICHERRY: "PY",
};

export const tableGridLabelsMapping = {
  invoice_no: "Reference Number",
  vendor_invoice_no: "Vendor Invoice Number",
  invoice_date: "Invoice Date",
  agency_name: "Agency Name",
  policy_number: "Policy Number",
  policy_start_date: "Policy Start Date",
  policy_end_date: "Policy End Date",
  policy_status: "Policy Status",
  claim_no: "Claim Number",
  claim_number: "Claim Number",
  Claim_Number: "Claim Number",
  hdfc_ergo_gst_number: "HDFC ERGO GST Number",
  gst_no: "GST Number",
  gst_number: "GST Number",
  product_category: "Product Category",
  charge_name: "Charge Name",
  charge_amount: "Charge Amount",
  tax: "TAX",
  tax_amount: "TAX Amount",
  total_amount: "Total Amount",
  customer_address: "Customer Address",
  customer_city: "Customer City",
  customer_pincode: "Customer Pincode",
  customer_registered_email_id: "Customer Reg. Email ID",
  customer_registered_mobile_number: "Customer Reg. Mobile No.",
  customer_state: "Customer State",
  hypothecation_with: "Hypothecation With",
  insured_details: "Insured Details",
  lob_name: "LOB",
  ncb_amount: "NCB Amount",
  nominee_details: "Nominee Details",
  policy_inception_date: "Policy Inception Date",
  policy_term_or_tenure: "Policy Term/Tenure",
  policy_type: "Policy Type",
  product_name: "Product Name",
  sub_product_plan_name: "Sub Product Plan Name",
  total_premium: "Total Premium",
  end_date: "End Date",
  inspection_status: "Inspection Status",
  previous_policy_details: "Previous Policy Details",
  previous_policy_number: "Previous Policy Number",
  start_date: "Start Date",
  cc_capacity: "CC Capacity",
  coverage_name: "Coverage Name",
  customer_name: "Customer Name",
  dob: "DOB.",
  idv: "IDV.",
  manufacturing_year: "Manufacturing Year",
  registration_date: "Registration Date",
  rto_location: "RTO Location",
  seating_capacity: "Seating Capacity",
  vehicle_chasis_no: "Vehicle Chasis No",
  vehicle_make: "Vehicle Make",
  vehicle_model_variant: "Vehicle Model Variant",
  policy_issue_date: "Policy Issue Date",
  survey_report: "Survey Report",
  diagnostic_report: "Diagnostic Report",
  break_in_pi_report: " Break In (Pi Report)",
  inspection_photo_image: "Inspection - Photo Image",
  proposal_no: "Proposal No",
  proposal_status: "Proposal Status",
  intermediary_name: "Intermediary Name",
  intermediary_code: "Intermediary Code",
  primary_sales_manager: "Primary Sales Manager",
  secondary_sales_manager: "Secondary Sales Manager",
  investigation_details: "Investigation Details",
  uw_remarks: "UW Remarks",
  total_sum_insured: "Total Sum Insured",
  cb_amount: "CB Amount",
  deductible: "Deductible",
  name_of_proposer: "Name Of Proposer",
  name_of_all_insured: "name Of All Insured",
  type_of_insured: "Type Of Insured",
  relationship: "Relationship",
  sum_insured: "Sum Insured",
  vehicleNumber: "Vehicle Number",
  vehicleManufacturerName: "Vehicle Manufacturer Name",
  model: "Model",
  vehicleColour: "Vehicle Colour",
  type: "Type",
  bodyType: "Body Type",
  regAuthority: "Reg Authority",
  regDate: "Reg Date",
  rcExpiryDate: "RC Expiry Date",
  recommendation_date: "Recommendation Date",
  vehicleSeatCapacity: "Vehicle Seat Capacity",
  isCommercial: "Is Commercial",
  presentAddress: "Present Address",
  presentPincode: "Pincode",
  permanentAddress: "Permanent Address",
  permanentPincode: "Pincode",
  class: "Class",
  chassis: "Chassis",
  engine: "Engine",
  status: "Status",
  statusAsOn: "Status As On",
  vehicleManufacturingMonthYear: "Vehicle Manufacturing Month Year",
  vehicleCubicCapacity: "Vehicle Cubic Capacity",
  vehicleCategory: "Vehicle Category",
  vehicleCylindersNo: "Vehicle Cylinders No",
  blacklistStatus: "Blacklist Status",
  owner: "Owner",
  ownerFatherName: "Owner Father Name",
  ownerCount: "Owner Count",
  vehicleInsuranceCompanyName: "Vehicle Insurance Company Name",
  vehicleInsuranceUpto: "Vehicle Insurance Upto",
  name: "Name",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  title: "Title",
  typeOfHolder: "Type Of Holder",
  isIndividual: "Is Individual",
  isValid: "Is Valid",
  number: "Number",
  panStatus: "Pan Status",
  panStatusCode: "Pan Status Code",
  aadhaarSeedingStatus: "Aadhaar Seeding Status",
  aadhaarSeedingStatusCode: "Aadhaar Seeding Status Code",
  lastUpdatedOn: "Last Updated On",
  message: "Message",
  dateOfIssue: "Issue Date",
  expiryDate: "Expiry Date",
  verified: "Verified",
  ageBand: "Age Band",
  mobileNumber: "Mobile Number",
  state: "State",
  gender: "Gender",
  challanNumber: "Challan Number",
  challanDate: "Challan Date",
  amount: "Amount",
  challanStatus: "Challan Status",
  offenseDetails: "Offense Details",
  accusedName: "Accused Name",
  accident_spot_verification_id: "Accident spot verification ID",
  eye_witness: "Eye witness",
  eye_witness_name: "Eye witness name",
  spot_cause_matches_loss: "Spot cause matches loss",
  remarks: "Remarks",
  sopt_sketch_matches_loss: "sopt sketch matches loss",
  relevant_documents: "relevant documents",
  created_by: "Created By",
  isActive: "Is Active",
  createdAt: "Created At",
  updatedAt: "Updated At",
  case_id: "Case ID",
  vendor_employee_id: "Vendor employee Id",
  block_master_id: "Block Master Id",
  premium_non_premium_bearing: "Premium Non-Premium Bearing",
  endorsement_reason: "Endorsement Reason",
  mobile_number: "Mobile Number",
  present_address: "Present Address",
  product_type: "Product Type",
  proposal_name: "Proposal Name",
  courier: "Courier",
  delivery_date: "Delivery Date",
  delivery_status: "Delivery Status",
  dispatch_date: "Dispatch Date",
  dispatch_type: "Dispatch Type",
  first_return_date: "First Return Date",
  pod_no: "Pod No",
  endorsement_no: "Endorsement No",
  premium_amt: "Premium Amt",
  transaction_date: "Transaction Date",
  type_of_endorsement: "Type of Endorsement",
  City: "City",
  Address: "Address",
  Pin_Code: "Pin Code",
  State: "State",
  Capacity: "Capacity",
  capacity_health: "Capacity",
  pincode: "Pincode",
  Nature_of_Firm: "Nature of Firm",
  LOB: "LOB",
  Sub_Product: "Sub – Product",
  PAN: "PAN",
  TAN: "TAN",
  CST: "CST",
  GST_No: "GST No.",
  HSN_No: "HSN No.",
  Sac_No: "Sac No.",
  MSME: "MSME",
  MSME_Registration_No: "MSME Registration No",
  Contact_Details: "Contact Details",
  Registration_Address: "Registration Address",
  Contact_Person_1: " Contact Person 1",
  Contact_Person_2: " Contact Person 2",
  Account_Number: " Account Number",
  Bank_Name: "Bank Name",
  Branch_Name: "Branch Name",
  IFSC_Code: "IFSC Code",
  Payment_Method: "Payment Method",
  Hospital_Name: "Hosiptal Name",
  IM_Action: "IM - Action",
  RM_Action: "RM - Action",
  NM_Action: "NM - Action",
  NH_Action: "NH - Action",
  Address: "Address",
  accident_spot_distance: "Accident-Spot-Distance",
  accident_took_place_state: "Accident-took-place-state",
  any_other_details: "Any-other-details ",
  bills_fake: "Bills-fake",
  district: "District",
  doctor_name: "Doctor-Name",
  fake_bill_amount: "Fake-Bill-Amount",
  hospital_name: "Hospital-name",
  injury_details: "Injury-Details ",
  medical_records: "Medical-Records",
  total_bills: "total_bills",
  treatment_details_id: "Treatment-Details-Id",
  vendor_company_id: "Vendor-Company-Id",
  wound_certificate: "Wound-Certificate",
  admission_date: "Admission-Date  ",
  discharge_date: "Discharge-Date",
  accused_driver_name: "Accused-Driver-Name",
  accused_veh_reg_no: "Accused-veh-Reg-No ",
  accused_vehicle: "accused_vehicle  ",
  accused_vehicle_color: "accused_vehicle_color  ",
  chargesheet_filed: "chargesheet_filed  ",
  complainant_petitioner_relation: "complainant_petitioner_relation  ",
  delay_in_fir: "delay_in_fir",
  fir_against_vehicle: "fir_against_vehicle  ",
  fir_date: "fir_date",
  fir_delay_reason: "fir_delay_reason  ",
  fir_filed: "fir_filed",
  fir_id: "fir_id  ",
  fir_lodged_by: "fir_lodged_by",
  fir_no: "fir_no",
  fir_time: "fir_time ",
  investigating_officer_rank: "investigating_officer_rank",
  investigation_officer_name: "investigation_officer_name  ",
  mv_act_and_others: "mv_act_and_others  ",
  petition_driver_name: "petition_driver_name  ",
  police_station_name: "  police_station_name",
  basis: "basis",
  charge_sheet_against_vehicle: "charge_sheet_against_vehicle ",
  dependency_issue: "dependency_issue ",
  driver_implant: "driver_implant",
  drunk_drive: "drunk_drive",
  fake_dl: "fake_dl  ",
  fraud_case: "fraud_case ",
  fraud_id: "fraud_id  ",
  further_course_of_action: "further_course_of_action  ",
  gratitious_passengers: "gratitious_passengers",
  hire_n_reward: "hire_n_reward  ",
  invalid_dl: "invalid_dl ",
  no_dl: "no_dl ",
  not_covered_under_policy: "not_covered_under_policy  ",
  occupants_overseating: "occupants_overseating ",
  other: "other ",
  permit_violations: "permit_violations",
  remarried_female_claimant: "remarried_female_claimant ",
  vehicle_implant: "vehicle_implant",
  cause_of_death: "cause_of_death ",
  conclusion: "conclusion ",
  health_post_mortem_details_id: "health_post_mortem_details_id ",
  hospital_address: "hospital_address ",
  "Product Type": "Product Type",
  opinion_and_finding: "opinion_and_finding ",
  other_claim_observations: "other_claim_observations ",
  panchanama_injuries_corelates_injuries_in_PM:
    "panchanama_injuries_corelates_injuries_in_PM ",
  pending_work: "pending_work",
  process_for_fee_payment: "process_for_fee_payment",
  recommendation: "recommendation ",
  remark: "remark",
  remark_on_content: "remark_on_content ",
  viscera_obtained: "viscera_obtained",
  viscera_report: "viscera_report ",

  "FIR Flag": "FIR Flag",
  "Address 1": "Address 1",
  "Address 2": "Address 2",
  "Address 3": "Address 3",
  "Caller Name at call center": "Caller Name at call center",
  "Caller Type": "Caller Type ",
  "Claim Intimation Contact Details": "Claim Intimation Contact Details",
  "Claim Officer Mobile": "Claim Officer Mobile",
  "Claim Officer Name": "Claim Officer Name",
  "Claim Registered by": "Claim Registered by",
  "Claim Status": "Claim Status",
  "Claim Type": "Claim Type",
  Claim_Number: "Claim Number",
  Claimant_name: "Claimant Name",
  "Contact Number": "Contact Number",
  "Contact Person": "Contact Person",
  DL: "DL",
  DOB: "DOB",
  "Date and time of call person contacted":
    "Date and time of call person contacted",
  "Driver DOB": "Driver DOB",
  "Driver Name": "Driver Name",
  "Estimated Liability": "Estimated Liability",
  "Fax No": "Fax No",
  "Fir Date": "Fir Date",
  "GC Notification Date": "GC Notification Date",
  "Initial Reserve Amount": "Initial Reserve Amount",
  "Sub Product Name": "Sub Product Name",
  "Product Name": "Product Name",
  "Insured DOB": "Insured DOB",
  "Insured Name": "Insured Name",
  "Intimation Date": "Intimation Date",
  "Landline Number": "Landline Number",
  "Last document received date": "Last document received date",
  "Loss City": "Loss City",
  "Loss Date": "Loss Date",
  "Loss Location": "Loss Location",
  "Loss Region": "Loss Region",
  "Loss State": "Loss State",
  "Loss Type": "Loss Type",
  "Name of driver in intimation sheet": "Name of driver in intimation sheet",
  "Nature of Loss": "Nature of Loss",
  "Notified By": "Notified By",
  "Notified date": "Notified date",
  "Past Claim History": "Past Claim History",
  "Policy End Date": "Policy End Date",
  "Policy Location": "Policy Location",
  "Previous Claim": "Previous Claim",
  "Previous claim rejection reason ": "Previous claim rejection reason ",
  "Registration Date": "Registration Date",
  Remarks: "Remarks",
  "Product Type": "Product Type",
  "Reserve Amount": "Reserve Amount",
  "Surveyor Mobile Number": "Surveyor Mobile Number",
  "Surveyor Name": "Surveyor Name",
  "Estimated Liability - GC": "Estimated Liability - GC",
  Age: "Age",
  Ailment: "Ailment",
  "Any other Details": "Any other Details",
  "Cause of loss": "Cause of loss",
  "Claim Amount": "Claim Amount",
  "Corporate Name": "Corporate Name",
  "Date of Intimation to Company": "Date of Intimation to Company",
  "Date of Loss": "Date of Loss",
  "Date of return": "Date of return",
  "Date of travel": "Date of travel",
  Diagnoisis: "Diagnosis",
  "Doctor IMC No": "Doctor IMC No",
  "Employer Name": "Employer Name",
  "Hospital Address": "Hospital Address",
  "Hospital Name": "Hospital Name",
  "Intimated by": "Intimated by",
  "Policy Inception Date": "Policy Inception Date",
  "Present Address": "Present Address",
  claim_number: "Claim number",
  "created At": "created at",
  State: "State",
  "Sum Insured": "Sum Insured",
  "Treating Doctor Name": "Treating Doctor Name",
  "Trigger for investigation": "Trigger for investigation",
  "Type of claim": "Type of claim",
  "Zip Code": "Zip Code",
  "Query Generated": "Query Generated",
  "Date of Admission": "Date of Admission",
  "Date of Discharge": "Date of Discharge",
  "Type of Hospital": "Type of Hospital",
  "Doctors Name": "Doctors Name",
  "Extention Data": "Extention Data",
  "Product Details": "Product Details",
  "Death Reason": "Death Reason",
  "Nature of Injury": "Nature of Injury",
  "Hospital ID": "Hospital ID",
  "Previous claim rejection reason - Remarks":
    "Previous claim rejection reason - Remarks",
  "Court Location": "Court Location",
  "Claim Reference no": "Claim Reference no",
  "Policy State": "Policy State",
  "Workshop Name": "Workshop Name",
  "Workshop Contact No": "Workshop Contact No",
  "Workshop Location": "Workshop Location",
  "Customer Name": "Customer Name",
  "Policy Number": "Policy Number",
  "Product Name": "Product Name",
  segmentation: "Segmentation",
  "Registration Date": "Registration Date",
  casecreatedby: "Created By",
  updated_by: "Updated By",
  customer_type: "Customer Type",
  trigger_source: "Trigger Source",
  severity: "Severity",
  case_closed_date: "Case Closed Date",
  case_status: "Case Status",
  sub_product_name: "Sub Product",
  "CB Amount": "CB Amount",
  "Case Description": "Case Description",
  "Created By": "Created By",
  "Customer City": "Customer City",
  "Customer Pincode": "Customer Pincode",
  "Customer Proposer": "Customer Proposer",
  "Customer State": "Customer State",
  "Hypothecation With": "Hypothecation With",
  "Insured Details": "Insured Details",
  "NCB Amount": "NCB Amount",
  "Nominee Details": "Nominee Details",
  "Nominee Name": "Nominee Name",
  "Plan Name": "Plan Name",
  "Total Premium": "Total Premium",
  "Total Sum Insured": "Total Sum Insured",
  "Updated By": "Updated By",
  "Updated At": "Updated At",
  claim_all_product_id: "Claim All Product ID",
  address1: "Address 1",
  address2: "Address 2",
  address3: "Address 3",
  age: "Age",
  ailment: "Ailment",
  any_other_details: "Any Other Details",
  caller_name_at_call_center: "Caller Name at Call Centre",
  caller_type: "Caller Type",
  case_description: "Case Description",
  cause_of_loss: "Cause Of Loss",
  city: "City",
  claim_aAndH_id: "Claim AANDH ID",
  claim_amount: "Claim Amount",
  claim_intimation_contact_details: "Claim Intimation Contact Detail",
  claim_motor_id: "Claim Motor ID",
  claim_number: "Claim Number",
  claim_officer_name: "Claim Officer Name",
  claim_officer_mobile: "Claim Officer Mobile",
  claim_reference_no: "Claim Reference No.",
  claim_registered_by: "Claim Registered By",
  claim_status: "Claim Status",
  claim_type: "Claim Type",
  claimant_name: "Claimant Name",
  claimant_number: "Claimant Number",
  contact_number: "Contact Number",
  contact_person: "Contact Person",
  corporate_name: "Corporate Name",
  court_location: "Court Location",
  "Claim Intimation Type": "Claim Intimation Type",
  date_and_time_of_call_person_contacted:
    "Date & Time of call person Contacted",
  date_of_admission: "Date of Admission",
  date_of_discharge: "Date of Discharge",
  date_of_intimation_to_company: "Intimation Date",
  date_of_loss: "Loss Date",
  date_of_return: "Date of Return",
  date_of_travel: "Date of Travel",
  death_reason: "Death Reason",
  diagnosis: "Diagnosis",
  dl: "DL",
  doctor_imc_no: "Doctor IMC no.",
  doctors_name: "Doctor Name",
  driver_dob: "Driver DOB",
  driver_name: "Driver Name",
  employer_name: "Employer Name",
  estimated_liability_gc: "Estimated Liability GC",
  extention_data: "Extension Data",
  fax_no: "Fax No.",
  fir_date: "FIR Date",
  fir_flag: "FIR Flag",
  gc_notification_date: "GC Notification Date",
  hospital_address: "Hospital Address",
  hospital_id: "Hospital ID",
  hospital_name: "Hospital Name",
  initial_reserve_amount: "Initial Reserve Amount",
  injury_nature: "Injury Nature",
  insured_dob: "Insured DOB",
  insured_name: "Insured Name",
  intimated_by: "Intimated By",
  intimation_date: "Intimation Date",
  landline_number: "Landline Number",
  last_doc_received_date: "Last Doc Received Date",
  lob_id: "LOB ID",
  loss_city: "Loss City",
  loss_date: "Loss Date",
  loss_location: "Loss Location",
  loss_region: "Loss Region",
  loss_state: "Loss State",
  loss_type: "Loss Type",
  name_of_driver_in_intimation_sheet: "Name of Driver in Intimation Sheet",
  nature_of_loss: "Nature of Loss",
  neft_details: "NEFT Details",
  notified_by: "Notify by",
  notified_date: "Notified Date",
  past_claim_history: "Past Claim History",
  policy_location: "Policy Location",
  policy_state: "Policy State",
  previous_claim: "Previous Claim",
  previous_claim_rejection_reason_remarks:
    "Previous Claim Rejection Reason remark",
  product_details: "Product Details",
  query_generated: "Query Generated",
  query_reply: "Query Reply",
  reserve_amount: "Reserve Amount",
  surveyor_mobile_number: "Surveyor Mobile Number",
  surveyor_name: "Surveyor Name",
  treating_doctor_name: "Treating Doctor Name",
  trigger_for_investigation: "Trigger for Investigation",
  type_of_claim: "Claim Type",
  type_of_hospital: "Hospital Type",
  workshop_contact_number: "Workshop Contact No.",
  workshop_name: "Workshop Name",
  zip_code: "Zip Code",
  im_name: "Assigned IM",
  CLM_APPROX_CLAIM_AMOUNT: "CLM APPROX CLAIM AMOUNT",
  CLM_CLAIMED_AMT: "CLM CLAIMED AMT",
  CLM_INT_LOSS_RES_AMT: "CLM INT LOSS RES AMT ",
  CLM_LOSS_ESTIMATE_AMNT: "CLM LOSS ESTIMATE AMNT",
  CLM_MAIN_STATUS: "CLM MAIN STATUS ",
  CLM_MOVEMENT_STATUS: "CLM MOVEMENT STATUS ",
  CLM_REPUDIATION_REASON: "CLM REPUDIATION REASON ",
  CLM_RE_STATUS: " CLM RE STATUS ",
  CLM_STATUS: "CLM STATUS",
  "Claim Loss Description": "Claim Loss Description",
  bimasathi_name: "BIMA Saathi Name",
  locations: "BIMA Saathi Location",
  chase_allocation_date: "CHASE Allocation Date",
  findings_submission_date: "Findings Submission Date",
  investigation_count: "Investigation Count",
  case_grade: "Case Grade",
  grade_status: "Grade Status",
  grade_sub_status: "Grade sub-status",
  fraud_type: "FRAUD TYPE",
  details: "Details",
  vendor_cd_code: "GC Code",
  "Invoice Amount": "Invoice Amount",
  "Invoice No": "Vendor Invoice Number",
  "Payment Status": "Payment Status",
  "Payee Name": "Payee Name",
  "NT_Cheque Date": "Payment date",
  "Bank Name": "Payee Bank Name",
  "NT_Cheque No": "UTR No",
  "Payment Mode": "Payment Mode",
  "Bank Branch Name": "Payee Bank Branch Name",
  CLAIM_NUMBER: "Claim Number",
  net_payable: "Net Payable",
  mode_of_handover: "Mode Of Handover",
  issueDate: "Issue Date",
  "Chase created Date": "Chase created Date",
};

export const getInvestigatorListwithLabel = (investigator = []) => {
  return investigator.map((item) => {
    return { id: item, label: `Investigator ${item}` };
  });
};
export const currentRoleNameObj = {
  RM: "Regional Manager",
  QC: "Quality Check",
  NM: "National Manager",
  NH: "National Head",
  IM: "Investigation Manager",
};

export const vendorCountNumber = [
  { vendor: 1 },
  { vendor: 2 },
  { vendor: 3 },
  { vendor: 4 },
  { vendor: 5 },
  { vendor: 6 },
  { vendor: 7 },
  { vendor: 8 },
  { vendor: 9 },
  { vendor: 10 },
  { vendor: 11 },
  { vendor: 12 },
  { vendor: 13 },
  { vendor: 14 },
  { vendor: 15 },
];

export const tableSelectedCellColors = {
  caseManagement: {
    Open: "#FFDFCB",
    Hold: "#CCE6FF",
    Allocated: "#E5CFFF",
    QC: "#CDF0DD",
    Recommended: "#FEEFB6",
    Submitted: "#FCD3DE",
    Rejected: "#CDDAFC",
  },
  approval: {
    Case: "#FFDFCB",
    Invoice: "#E5CFFF",
    Hospital: "#CDF0DD",
    Garage: "#FEEFB6",
    Police: "#FCD3DE",
    Vendor: "#CDDAFC",
  },
};
export const typeOfAudio = ["mp3", "m4a", "aac", "flac", "wav", "mpeg"];
export const typeOfPlayer = ["mp4", "hevc"];

export const truncateMiddleText = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }
  const firstPart = str.substr(0, maxLength / 2);
  const lastPart = str.substr(str.length - maxLength / 2);
  return `${firstPart}.....${lastPart}`;
};
export const vendorOnboardingSteppers = [
  {
    iconSvg: `${imageBaseURL}/Svg/PanGstDetailsIcon.svg`,
    label: "PAN & GST Details",
    component: "PanGstDetails",
  },
  {
    iconSvg: `${imageBaseURL}/Svg/CompanyDetailsIcon.svg`,
    label: "Company Details",
    component: "CompanyDetails",
  },
  {
    iconSvg: `${imageBaseURL}/Svg/BankDetailsIcon.svg`,
    label: "Bank Details",
    component: "BankDetails",
  },
  {
    iconSvg: `${imageBaseURL}/Svg/ServiceDetailsIcon.svg`,
    label: "Service Details",
    component: "ServiceDetails",
  },
  {
    iconSvg: `${imageBaseURL}/Svg/DocumentsIcon.svg`,
    label: "Documents",
    component: "Documents",
  },
];

export function formatDateString(dateString) {
  if (
    typeof dateString === "string" &&
    dateString.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z/)
  ) {
    // If the string is a valid date string, parse it using new Date and format it
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
  }
  return dateString;
}

// Function to create an array based on specific indices and lengths
export const tabsToBeShown = (arr, indices) => {
  const result = Array(8); // Initialize an array of 8 elements with empty strings
  indices.forEach((index) => {
    result[index] = arr[index];
  });
  return result;
};

export const getFilterActionList = (actionList, labelToExclude) => {
  // in actionList pass array of an action and in labeltoexclude pass string that u want to remove
  return actionList.filter((action) => action?.label !== labelToExclude);
};

export const filterTableDataByRole = (
  data,
  currentRole,
  firstTabVal,
  ignoreRole
) => {
  const filteredData = { ...data };
  if (ignoreRole?.includes(currentRole)) {
    delete filteredData?.[firstTabVal]?.row?.vendor_assessment;
  }
  return filteredData;
};

// convert file type to blob
export const fileConverter = async (filesData) => {
  let convertedFiles = [];
  for (const fileData of filesData) {
    const { fileUrl, fileName } = fileData;
    const file = await fetch(fileUrl)
      .then((res) => res.blob())
      .then(
        (blob) =>
          new File([blob], fileName, {
            type:
              blob.type === "text/plain"
                ? `image/${fileName.split(".").pop().toLowerCase()}`
                : blob.type,
          })
      );
    convertedFiles.push(file);
  }

  return convertedFiles;
};

// convert fileSize
export const formatFileSize = function (bytes) {
  const sufixes = ["B", "kB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};

export const irdaiDetails = {
  groundOfFraudRejection: "Ground of Fraud Rejection",
  fraudCommittedBy: "Fraud Committed By",
  nameOfSuspect: "Name of Suspect/Fraudster",
  suspectIdProofName: "Suspect/Fraudster ID Proof Name",
  suspectIdProofNumber: "Suspect/Fraudster ID Proof Number",
  investigationAgencyName: "Investigation Agency Name",
  investigatorPanCard: "Investigator PAN Card",
  investigatorGstNumber: "Investigator GST Number",
};
