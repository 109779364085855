import React, { useEffect } from "react";
import "./App.css";
import ToastSnackbar from "./CommonComponents/ToastNotification";
import GloblaContextProvider from "./Context/GloblaContext";
import Index from "./Routes";
import { ThemeProvider, createTheme } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackComponent from "./CommonComponents/ErrorFallbackComponent";
import { analyticsInitialize } from "./Utils/helper";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { isLoadingStateReducer } from "./Redux/Slice/loginSlice";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const GTAGKEY = process.env.REACT_APP_GTAG_KEY;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  button: {
    fontFamily: "Inter",
    color: "yellow",
  },
});
analyticsInitialize();

export const initGA = () => {
  ReactGA.initialize(GTAGKEY);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    initGA();
    logPageView();
    dispatch(isLoadingStateReducer(false));
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_SITE_KEY}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
        <GloblaContextProvider>
          <ThemeProvider theme={theme}>
            <ToastSnackbar />
            <Index />
          </ThemeProvider>
        </GloblaContextProvider>
      </ErrorBoundary>
    </GoogleReCaptchaProvider>
  );
}

export default App;
